import { useContext, useEffect } from "react";
import { MenuContext } from "../../Provider/MenuProvider";
import { StakingWapper } from "../Staking/styled";
import { BonusBalanceBox, BonusBalanceBoxTitle } from "../Bonus/styled";
import {
  InfoConten,
  TokenomicsConten,
  TokenomicsContenChrt,
  TokenomicsValue,
} from "./style";
import TokenChart from "../../assets/Withdraw/chart.svg";

const TokenomicsPage = () => {
  const { setTitle }: any = useContext(MenuContext);
  useEffect(() => {
    setTitle("Tokenomics");
  }, []);
  return (
    <StakingWapper>
      <BonusBalanceBox>
        <BonusBalanceBoxTitle>
          <p>Token Information</p>
        </BonusBalanceBoxTitle>
        <InfoConten>
          <div>
            <h3>
              Token name: <p>TON Holding</p>{" "}
            </h3>
            <h3>
              Token Ticker: <p>$iTON</p>{" "}
            </h3>
            <h3>
              Token Type: <p>The Open Network (TON)</p>{" "}
            </h3>
          </div>
          <span></span>
          <div>
            <h3>
              Demical:: <p>9</p>{" "}
            </h3>
            <h3>
              Total Supply: <p>500,000,000 $iTON</p>{" "}
            </h3>
          </div>
        </InfoConten>
      </BonusBalanceBox>
      <TokenomicsConten>
        <TokenomicsValue>
          <h3>
            17% <span>Treasury</span>
          </h3>
          <h3>
            12% <span>Investors</span>
          </h3>
          <h3>
            12% <span>Team</span>
          </h3>
          <h3>
            5% <span>Marketing</span>
          </h3>
        </TokenomicsValue>
        <TokenomicsContenChrt>
          <img src={TokenChart} />
        </TokenomicsContenChrt>
        <TokenomicsValue>
          <h3>
            <span>Staking</span> 20%
          </h3>
          <h3>
            <span>Airdrop</span> 7%
          </h3>
          <h3>
            <span>Incentives</span> 8%
          </h3>
          <h3>
            <span>Liquidity</span> 19%
          </h3>
        </TokenomicsValue>
      </TokenomicsConten>
    </StakingWapper>
  );
};
export default TokenomicsPage;
