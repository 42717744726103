import { Submit } from "../Staking/styled";
import { BoxTitleRadius } from "../Dashboard/styled";
import NotiIcon from "../../assets/Staking/CompoundBadge.svg";
import { useContext, useEffect, useState } from "react";
import { MenuContext } from "../../Provider/MenuProvider";
import { RootState, useDispatch, useSelector } from "../../store/reDucStore";
import { getTokenInfo } from "../../store/Slices/Token";
import toast from "react-hot-toast";
import { useTonAddress } from "@tonconnect/ui-react";
import NewBg from "../../assets/Withdraw/wdrawBg.png";
import wdrawIcon from "../../assets/Withdraw/WithdrawIcon.png";
import {
  WidthrawSubmit,
  getBalance,
  getFee,
  getWidthrawList,
} from "../../store/Slices/Withdraw";
import { getDasboardInfo } from "../../store/Slices/Dashboard";
import styled from "styled-components";
import { PannerNetword } from "../Network/style";
import {
  TableWithdrawMobile,
  WithdrawBalance,
  WithdrawBalanceText,
  WithdrawConten,
  WithdrawHisBox,
  WithdrawInput,
  WithdrawInputAmount,
  WithdrawInputBox,
  WithdrawInputGroup,
  WithdrawWapper,
} from "./style";
import TableWithdraw from "./Table";
import { ContextProviderWrapper } from "../../components/Context";
import { formatNumber } from "../../utils/formatNumber";

const Withdraw = () => {
  const [amount, setAmount] = useState(0);
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const [loadingSub, setLoadingSub] = useState(false);
  const { setTitle, setDes }: any = useContext(MenuContext);
  const address = useTonAddress();
  useEffect(() => {
    setTitle("Withdraw");
    setDes("Easy to withdraw from your wallet");
  }, []);
  const dispatch = useDispatch();

  const TokenInfo = useSelector((state: RootState) => state.token.TokenInfo);
  const WidthrawList = useSelector(
    (state: RootState) => state.withdraw.WidthrawList
  );
  const balanceWithdraw = useSelector(
    (state: RootState) => state.withdraw.balanceWithdraw
  );
  const Fee = useSelector((state: RootState) => state.withdraw.settingList);
  const [fee, setFee] = useState(0);
  useEffect(() => {
    if (
      Fee?.data?.find((item: any) => item.name === "Fee.WithDraw.Token.Percent")
        ?.value
    ) {
      setFee(
        Number(
          Fee?.data?.find(
            (item: any) => item.name === "Fee.WithDraw.Token.Percent"
          )?.value
        )
      );
    }
  }, [
    Fee?.data?.find((item: any) => item.name === "Fee.WithDraw.Token.Percent")
      ?.value,
  ]);
  useEffect(() => {
    if (localStorage.getItem("token") && address) {
      dispatch(getDasboardInfo());
      dispatch(getTokenInfo());
      dispatch(getWidthrawList());
      dispatch(getBalance());
      dispatch(getFee());
    }
  }, [localStorage.getItem("token"), address]);

  const handleWithdraw = async () => {
    if (!Number(amount) || Number(amount) > balanceWithdraw?.money_token) {
      toast.error("Balance Not Enough");
      return;
    }
    setLoadingSub(true);
    const result = await dispatch(
      // @ts-ignore
      WidthrawSubmit({
        amount: Number(amount),
        type: "TON",
      })
    );
    if (result.payload.status === 200) {
      dispatch(getDasboardInfo());
      dispatch(getTokenInfo());
      dispatch(getWidthrawList());
      dispatch(getBalance());
      dispatch(getFee());
      toast.success("Successfully");
    } else if (
      result.payload?.error?.data?.errorKey === `user.balanceNotEnough`
    ) {
      toast.error("Balance Not Enough");
    } else if (
      result.payload?.error?.data?.errorKey === `withdrawalRequest.pending`
    ) {
      toast.error("There is another transaction pending confirmation");
    } else {
      toast.error("There is an error. Please try again.");
    }
    setLoadingSub(false);
  };

  return (
    <>
      <WithdrawWapper>
        <PannerNetword bg={NewBg}>
          <div>
            <h3>Easily Withdraw</h3>
            <p>Providing the low fee of withdraw for your best experience</p>
          </div>
        </PannerNetword>
        <WithdrawConten>
          <WithdrawInputGroup>
            <WithdrawBalance>
              <img src={wdrawIcon} alt="icon" />
              <WithdrawBalanceText>
                <h6>Available</h6>
                <h3>
                  {" "}
                  {balanceWithdraw?.money_token
                    ? formatNumber(
                      (Math.floor(Number(balanceWithdraw?.money_token) * 100) / 100)?.toFixed(2)
                      )
                    : 0}{" "}
                  <img src="./assets/ton_symbol.svg" alt="icon" />
                </h3>
                <p>
                  ~
                  {TokenInfo?.prices?.USD && balanceWithdraw?.money_token
                    ? formatNumber(
                        (
                          Number(balanceWithdraw?.money_token) *
                          TokenInfo?.prices?.USD
                        )?.toFixed(2)
                      )
                    : 0}{" "}
                </p>
              </WithdrawBalanceText>
            </WithdrawBalance>
            <WithdrawInputBox>
              <BoxTitleRadius>
                <h3>Withdraw</h3>
              </BoxTitleRadius>
              <WithdrawInput>
                <WithdrawInputAmount>
                  <h3>Amount</h3>
                  <div>
                    <input
                      type="number"
                      pattern="0.00"
                      value={amount ? amount : ""}
                      onChange={(e: any) => {
                        e.target.value >= 0 && setAmount(e.target.value);
                      }}
                    />
                    <button
                      onClick={() => {
                        setAmount((Math.floor(Number(balanceWithdraw?.money_token))) || 0);
                      }}
                    >
                      Max
                    </button>
                  </div>
                  <p>
                    ~$ 
                    {TokenInfo?.prices?.USD
                      ? formatNumber(
                          (Number(amount) * TokenInfo?.prices?.USD)?.toFixed(2)
                        )
                      : 0}{" "}
                  </p>
                  <BoxNotification>
                    <div>
                      <img src={NotiIcon} alt="icon" />
                      <p>Fee Withdraw</p>
                    </div>
                    <h6>
                      <span>{fee}%</span>{" "}
                      {`(~$${
                        fee
                          ? `${
                              TokenInfo?.prices?.USD
                                ? formatNumber(
                                    (
                                      (Number(amount) *
                                        TokenInfo?.prices?.USD *
                                        fee) /
                                      100
                                    )?.toFixed(2)
                                  )
                                : 0
                            }`
                          : "0"
                      })`}
                    </h6>
                  </BoxNotification>
                  <Submit
                    onClick={() => {
                      !loadingSub && handleWithdraw();
                    }}
                    style={{
                      opacity:
                        amount && Number(amount) <= balanceWithdraw?.money_token
                          ? "1"
                          : "0.5",
                    }}
                  >
                    {" "}
                    {loadingSub ? "Loading..." : "Withdraw"}
                  </Submit>
                </WithdrawInputAmount>
              </WithdrawInput>
            </WithdrawInputBox>
            {isMobile && (
              <TableWithdrawMobile>
                <BoxTitleRadius>
                  <h3>History</h3>
                </BoxTitleRadius>
                <TableWithdraw WidthrawList={WidthrawList} />
              </TableWithdrawMobile>
            )}
          </WithdrawInputGroup>
          <WithdrawHisBox>
            {!isMobile && (
              <>
                <BoxTitleRadius>
                  <h3>History</h3>
                </BoxTitleRadius>
                <TableWithdraw WidthrawList={WidthrawList} />
              </>
            )}
          </WithdrawHisBox>
        </WithdrawConten>
      </WithdrawWapper>
    </>
  );
};

export default Withdraw;

const BoxNotification = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 12px;
  justify-content: space-between;
  padding: 4px !important;
  padding-right: 12px;
  margin-bottom: 8px;
  border-radius: 34px !important;
  background: var(--Gray-100, #2d2f35);
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px !important;
    border-radius: 50%;
    width: fit-content;
    gap: 12px;
    margin-bottom: 0px;
    img {
      width: 40px;
      height: 40px;
      max-width: 100%;
      max-height: 100%;
    }
  }
  p {
    color: var(--Gray-40, #80c2ff);
    leading-trim: both;
    text-edge: cap;
    font-family: AnekLatin-Regular;
    font-weight: 500;
    font-size: 16px;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.15px;
    width: fit-content;
    margin: 0px;
  }
  h6 {
    color: var(--Gray-Base, #c3c6cf);
    leading-trim: both;
    text-edge: cap;
    font-family: AnekLatin-Regular;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.16px;
    padding-right: 12px;
    span {
      color: var(--Blue-Base, #fff);
    }
  }
  @media screen and (max-width: 767px) {
    h6 {
      line-height: normal;
      padding-right: 0;
    }
  }
`;
