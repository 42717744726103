import { NavLink } from "react-router-dom"
import { NavMobileContainer } from "./styled"

const NavigationMoible = ({ setIsMenuMobile }: any) => {
  return (
    <NavMobileContainer>
      {dataNav.map((item, index) => {
        return (
          <NavLink to={item.href} key={index} onClick={() => {
            if (item.id === 5) {
              setIsMenuMobile(true)
            }
          }}>
            <figure>
              <img src={item.icon} alt="icon" />
            </figure>
            {item.text}
          </NavLink>
        )
      })}
    </NavMobileContainer>
  )
}

const dataNav = [
  {
    id: 1,
    icon: "./assets/navIcon/DashboardIcon.svg",
    text: "Dashboard",
    href: "/"
  },
  {
    id: 2,
    icon: "./assets/navIcon/stakingIcon.svg",
    text: "Staking",
    href: "/staking"
  },
  {
    id: 3,
    icon: "./assets/navIcon/networdIcon.svg",
    text: "Network",
    href: "/network"
  },
  {
    id: 4,
    icon: "./assets/navIcon/withdrawIcon.svg",
    text: "Withdraw",
    href: "/withdraw"
  },
  {
    id: 5,
    icon: "./assets/navIcon/menuIcon.svg",
    text: "Menu",
    href: "#"
  },
]

export default NavigationMoible