export const formatNumber = (num: any) => {
  if (num) {
    let number = Number(num);
    return new Intl.NumberFormat("en-US").format(number);
  } else {
    return "0";
  }
};

export const convertFixed = (value: any) => {
  if (Number(value) === 0)
    // nếu value < 0.00000009
    return value?.toFixed(2)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // thì show giá trị 9 sốthập phân
  if (Number(value) > 1e3)
    // nếu value lớn hơn 1e5 (100000)
    return value?.toFixed(0)?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // thì show giá trị 0 số thập phân
  if (Number(value) < 1 && Number(value) >= 9e-4)
    // nếu 1 > value >= 0.0009
    return value?.toFixed(4); // thì show giá trị 4 sốthập phân
  if (Number(value) < 9e-4 && Number(value) >= 9e-7)
    // nếu value < 0.00000009
    return value?.toFixed(7); // thì show giá trị 9 sốthập phân
  return value.toFixed(2);
};