import Arrow from "../../components/Arow";
import {
  AccountBox,
  BigNumber,
  BoxChangeValue,
  BoxTitle,
  BoxTitleRadius,
  BoxTotalContent,
  BoxValue,
  ProgesContent,
  DashboardContainer,
  DashboardLeft,
  DashboardRight,
  DashboardTitle,
  DashboardTotal,
  DashboardTotalBalance,
  DashboardWapper,
  ImgIcon,
  ProgressingBg,
  IconBox,
  OverviewBox,
  OverviewGroup,
  // SaleGroup,
  // SaleItem,
  // SaleItemDetail,
  // SaleItemGroup,
  // SaleTotalBox,
  // TotalDetails,
  TotalValue,
  ProgesBar,
  ProgesBox,
  ProgesValue,
  BoxLevel,
  BoxLevelImg,
  BoxLevelContent,
  ProgesTitle,
  AccountBlock,
  CarouselNewsContainer,
  CarouselNewsTitle,
  CarouselNewsParents,
  CarouselNewsItem,
  CarouselNewsParentsWrap,
  CarouseMBPagination,
} from "./styled";
import DashboardIcon from "../../assets/Dashboard/DashboardIcon.png";
import ProfileBox from "./ProfileBox";
import CardIcon1 from "../../assets/Dashboard/CardIcon1.png";
import CardIcon2 from "../../assets/Dashboard/CardIcon2.png";
import { useContext, useEffect, useState } from "react";
import { MenuContext } from "../../Provider/MenuProvider";
import { getDasboardInfo } from "../../store/Slices/Dashboard";
import { RootState, useDispatch, useSelector } from "../../store/reDucStore";
import { getNetwordInfo } from "../../store/Slices/NetWord";
import { usePairBalances } from "../../hook/useBalance";
import { getTokenInfo } from "../../store/Slices/Token";
import { useTonAddress } from "@tonconnect/ui-react";
import { getUserList } from "../../store/Slices/User";
import { getBalance, getWidthrawList } from "../../store/Slices/Withdraw";
import { formatNumber } from "../../utils/formatNumber";
import Thun from "../../assets/Dashboard/ProgesThum.svg";
import LevelIcon from "../../assets/Dashboard/LevelIcon.png";
import LogoAll from "../../assets/Dashboard/LogoAll.png";
import { ContextProviderWrapper } from "../../components/Context";
import { InvestStatus } from "../../store/Slices/invesmens";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styleSlide.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, EffectCoverflow, Pagination } from "swiper/modules";
import { useMediaQuery } from "react-responsive";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { setTitle, setDes }: any = useContext(MenuContext);
  const address = useTonAddress();
  const [persent, setPersent] = useState<any>(0);
  const StatusInvest = useSelector(
    (state: RootState) => state.investmens.StatusInvest
  );
  useEffect(() => {
    setTitle("Dashboard");
    setDes("");
  }, []);
  const dashboardList = useSelector(
    (state: RootState) => state.dashboard.dashBoardInfo
  );
  const NetwordInfo = useSelector(
    (state: RootState) => state.network.NetwordInfo
  );
  const UserProfile = useSelector((state: RootState) => state.User.UserList);
  const TokenInfo = useSelector((state: RootState) => state.token.TokenInfo);
  const balanceWithdraw = useSelector(
    (state: RootState) => state.withdraw.balanceWithdraw
  );

  const [investmentStatus, setInvestmentStatus] = useState(null);

  const fetchInvestmentStatus = async () => {
    const res = await dispatch(InvestStatus());
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      fetchInvestmentStatus();
    }
  }, [localStorage.getItem("token"), address]);

  useEffect(() => {
    if (localStorage.getItem("token") && address) {
      dispatch(getDasboardInfo());
      dispatch(getNetwordInfo());
      dispatch(getUserList());
      dispatch(getBalance());
      dispatch(getTokenInfo());
    }
  }, [localStorage.getItem("token"), address]);
  useEffect(() => {
    if (
      dashboardList &&
      dashboardList?.overview?.max_out_commission &&
      dashboardList?.overview?.total_direct_revenue
    ) {
      setPersent(
        Number(
          (dashboardList?.overview?.total_direct_revenue /
            dashboardList?.overview?.max_out_commission) *
            100
        ).toFixed(1)
      );
    }
  }, [dashboardList]);

  const { isMobile, isDesktop } = useContext(ContextProviderWrapper)!;

  return (
    <DashboardWapper>
      <DashboardTotal>
        <DashboardTitle>
          <h3>
            Welcome to <br style={{ display: isMobile ? "" : "none" }} />
            <span>TON Holding!</span>
          </h3>
          <p>
            Participating in a Dao-
            <br style={{ display: isMobile ? "" : "none" }} />
            powered decentralized
            <br style={{ display: isMobile ? "" : "none" }} /> investment
            platform
          </p>
        </DashboardTitle>
      </DashboardTotal>
      <DashboardContainer>
        <DashboardLeft>
          <DashboardTotalBalance>
            <ImgIcon src={DashboardIcon} />
            <div>
              <BoxTitle>
                <h3>Total Balance</h3>
              </BoxTitle>
              <BoxTotalContent>
                <TotalValue>
                  <BigNumber>
                    <h3>
                      {(balanceWithdraw?.money_token?.toFixed(2) &&
                        formatNumber(
                          balanceWithdraw?.money_token?.toFixed(2)
                        )) ||
                        0}{" "}
                      <img src="./assets/ton_symbol.svg" />
                    </h3>
                  </BigNumber>
                  <h4>
                    ~$
                    {(TokenInfo?.prices?.USD &&
                      balanceWithdraw?.money_token &&
                      formatNumber(
                        (
                          Number(balanceWithdraw?.money_token) *
                          TokenInfo?.prices?.USD
                        )?.toFixed(2)
                      )) ||
                      "--"}
                  </h4>
                </TotalValue>
              </BoxTotalContent>
            </div>
          </DashboardTotalBalance>
          <AccountBlock>
            <AccountBox>
              <BoxTitleRadius>
                <h3>Progressing</h3>
              </BoxTitleRadius>
            </AccountBox>
            <ProgressingBg>
              <ProgesContent>
                <div>
                  <h3>Your Staking</h3>
                  <p>
                    {dashboardList?.overview?.total_investment_token
                      ? new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                          .format(
                            dashboardList?.overview?.total_investment_token?.toFixed(
                              2
                            )
                          )
                          ?.replace("$", "")
                      : "0"}
                    <img
                      src="/assets/ton_symbol.svg"
                      style={{ maxHeight: "20px", marginLeft: "5px" }}
                      alt="ton_symbol"
                    />
                  </p>
                </div>
                <div>
                  <h3>Max out</h3>
                  <p>
                    {dashboardList?.overview?.max_out_commission
                      ? new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(
                          dashboardList?.overview?.max_out_commission?.toFixed(
                            2
                          )
                        )
                      : "0"}
                  </p>
                </div>
              </ProgesContent>
              <ProgesBar>
                <ProgesBox percent={persent}>
                  <img src={Thun} alt="proges" />
                  <ProgesTitle>
                    <h3>{persent}%</h3>
                  </ProgesTitle>
                  <ProgesValue percent={persent}></ProgesValue>
                </ProgesBox>
              </ProgesBar>
            </ProgressingBg>
          </AccountBlock>
          {/* <ProfileBox /> */}
        </DashboardLeft>
        <DashboardRight>
          <AccountBlock>
            <BoxTitleRadius>
              <h3>Overview</h3>
            </BoxTitleRadius>
            <OverviewGroup>
              <OverviewBox>
                <IconBox>
                  <img src={CardIcon1} />
                </IconBox>
                <BoxValue>
                  <p>Total Staking</p>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <h3 style={{ fontSize: "25px", margin: 0 }}>
                      {dashboardList?.overview?.total_investment_usd
                        ? new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(
                            dashboardList?.overview?.total_investment_usd?.toFixed(
                              2
                            )
                          )
                        : "$0"}
                    </h3>
                    {StatusInvest && (
                      <span style={{ fontSize: "15px" }}>
                        {StatusInvest === "PENDING" && "(In Progress)"}
                      </span>
                    )}
                  </div>
                </BoxValue>
              </OverviewBox>
              <OverviewBox>
                <IconBox>
                  <img src={CardIcon2} />
                </IconBox>
                <BoxValue>
                  <p>Total Revenue</p>
                  <h3>
                    {dashboardList?.overview?.total_direct_revenue
                      ? new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                        }).format(
                          dashboardList?.overview?.total_direct_revenue?.toFixed(
                            2
                          )
                        )
                      : "$0"}
                  </h3>
                </BoxValue>
              </OverviewBox>
            </OverviewGroup>
          </AccountBlock>
          <BoxLevel>
            <BoxLevelContent>
              <img src={LogoAll} alt="logo" />
              <div>
                <h3>Your level</h3>
                <p>
                  {dashboardList?.overview?.total_investment_usd === 0
                    ? "Nominator"
                    : UserProfile?.ranking
                    ? UserProfile?.ranking
                    : "Member"}
                </p>
              </div>
            </BoxLevelContent>
            <BoxLevelImg src={LevelIcon} />
          </BoxLevel>
        </DashboardRight>
      </DashboardContainer>
    </DashboardWapper>
  );
};

export default Dashboard;
