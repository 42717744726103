import dayjs from "dayjs"
import Table from "../../../components/Table"
import { BlueTable } from "../../Bonus/styled"
import { TextStatus } from "./styled"
import { useContext } from "react"
import { ContextProviderWrapper } from "../../../components/Context"
import { formatNumber } from "../../../utils/formatNumber"

const TableWithdraw = ({ WidthrawList }: any) => {
  const { isMobile } = useContext(ContextProviderWrapper)!
  return (
    <BlueTable>
      <Table
        data={WidthrawList?.data || []}
        keyObj={[
          {
            th: "ID Transaction",
            key: "history_id",
            callback: (data: any) =>
              `${data.history_id?.slice(
                0,
                4
              )}...${data.history_id?.slice(-4)}`,
          },
          {
            th: "Tx id",
            key: "tx_hash",
            callback: (data: any) => {
              if (isMobile) {
                return (
                  <a href={`https://tonviewer.com/transaction/${data.tx_hash}`} target="_blank">Tx hash: {data.tx_hash?.slice(0, 4)}...{data.tx_hash?.slice(-4)}</a>
                )
              } else {
                return  data.tx_hash ? <a href={`https://tonviewer.com/transaction/${data.tx_hash}`} target="_blank">{data.tx_hash?.slice(0, 4)}...{data.tx_hash?.slice(-4)}</a> : "--"
              }
            }
          },
          {
            th: "Type Payment",
            key: "remark",
            callback: (data: any) => {
              if (isMobile) {
                return (
                  <div>
                    <p>Type Transaction</p>
                    <p>
                      <img src="/assets/icon/table_arrow.svg" alt="icon" />
                      {data.remark}</p>
                  </div>
                )
              } else {
                return <p>{data.remark === "CLAIM" ? "Withdraw" : data.remark}</p>
              }
            }
          },
          {
            th: "Amount", key: "amount", callback: (data: any) => {
              if (isMobile) {
                return (
                  <div>
                    <p>Amount</p>
                    <p>{formatNumber(data.amount?.toFixed(2))}</p>
                  </div>
                )
              } else {
                return <p>{formatNumber(data.amount?.toFixed(2))}</p>
              }
            }
          },
          {
            th: "Datetime", key: "created_at", callback: (data: any) => {
              if (isMobile) {
                return (
                  <div>
                    <p>{dayjs(data.created_at).format("DD/MM/YYYY HH:MM:SS")}</p>
                  </div>
                )
              } else {
                return <p>{dayjs(data.created_at).format("DD/MM/YYYY")}</p>
              }
            }
          },
          {
            th: "Status", key: "status", callback: (data: any) => {
              if (isMobile) {
                return (
                  <div>
                    <TextStatus><p>{data.status}</p></TextStatus>
                  </div>
                )
              } else {
                return <TextStatus><p>{data.status}</p></TextStatus>
              }
            }
          },
        ]}
      />
    </BlueTable>
  )
}

export default TableWithdraw