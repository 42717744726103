import styled from "styled-components";

const Tabs = ({ TabsList, value, setValue }: any) => {
  return (
    <TabsContainer>
      {TabsList?.map((tab: any) => {
        return (
          <TabsBtn
            className={value.value === tab.value ? "active" : ""}
            key={tab.value}
            onClick={() => {
              setValue(tab);
            }}
            active={value.value === tab.value}
          >
            <h3>{tab.title}</h3>
            {tab.label !== null && tab.label !== undefined && (
              <span>{tab.label}</span>
            )}
          </TabsBtn>
        );
      })}
    </TabsContainer>
  );
};
export default Tabs;
export const TabsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 4px;
`;
export const TabsBtn = styled.div<{ active?: any }>`
  padding: 8px 24px;
  border-radius: 34px;
  background: ${({ active }) =>
    active ? "var(--Gray-80, #444750)" : ""};
  border: 1px solid ${({ active }) => (active ? "#444750" : "#878EA0")};
  display: flex;
  gap: 10px;
  transition: all 0.1s linear;
  cursor: pointer;
  h3 {
    color: ${({ active }) => (active ? "#fff" : "var(--Gray-Base, #878ea0)")};
    leading-trim: both;
    text-edge: cap;
    font-family: AnekLatin-Regular;
font-weight: 500;
    font-size: 15px;
    font-weight: 400;
    display: flex;
    align-items: center;
  }
  span {
    display: flex;
    padding: 4px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    min-width: 23px;
    background: ${({ active }) =>
    active ? "var(--Gray-80, #2D2F35)" : "#444750"};
    color: ${({ active }) => (active ? "var(--Gray-80, #E7E8EC)" : "#afb4c0")};
    leading-trim: both;
    text-edge: cap;
    font-family: "PlusJakartaSans";
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0.18px;
    border-radius: 50%;
  }
`;
