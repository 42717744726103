import { useContext, useEffect, useState } from "react";
import Tabs from "../Dashboard/Tabs";
import {
  AffilliateWapper,
  FillterTool,
  SearchIcon,
  SearchInput,
} from "./styled";
import IconSearch from "../../assets/Affilliate/searchIcon.svg";
import TreeNode from "./TabValues/treeNode";
import { BlueTable } from "../Bonus/styled";
import Table from "../../components/Table";
import { MenuContext } from "../../Provider/MenuProvider";
import { RootState, useDispatch, useSelector } from "../../store/reDucStore";
import { getAffiliateList, getNetwordInfo, getNetwordTree } from "../../store/Slices/NetWord";
import { BoxTitlePage } from "../Staking/styled";
import { useTonAddress } from "@tonconnect/ui-react";
import { ContextProviderWrapper } from "../../components/Context";
import CopyToClipboard from "react-copy-to-clipboard";
import toast from "react-hot-toast";

const Affiliate = () => {
  const { isMobile } = useContext(ContextProviderWrapper)!
  const [activeTab, setActiveTab] = useState(TabsList[0]);
  const { setTitle }: any = useContext(MenuContext);
  const [listAffiliate, setListAffiliate] = useState<any>([]);
  const address = useTonAddress()
  useEffect(() => {
    setTitle("Affiliate");
  }, []);
  const dispatch = useDispatch();
  const NetwordInfo = useSelector(
    (state: RootState) => state.network.NetwordInfo
  );
  const afiliteList = useSelector(
    (state: RootState) => state.network.afiliteList
  );
  
  useEffect(() => {
    if (localStorage.getItem("token") && address) {
      dispatch(getAffiliateList());
    }
  }, [localStorage.getItem("token"), address]);

  useEffect(() => {
    if (NetwordInfo?.left && NetwordInfo?.right) {
      setListAffiliate([...NetwordInfo?.left, ...NetwordInfo?.right]);
    }
  }, [NetwordInfo]);

  const [nodeData, setNodeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [Edges, setEdges] = useState([]);

  const nodeTree = useSelector((state: RootState) => state.network.nodeTree);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(getNetwordTree());
    }
  }, [localStorage.getItem("token")]);

  useEffect(() => {
    setLoading(true);
    if (nodeTree) {
      const edgeType = "smoothstep";
      let dataConver: any = [];
      let EdgesConver: any = [];
      const handleConvertData = (
        data: any,
        edges: any,
        number: any,
        preId: any
      ) => {
        if (data.node) {
          if (dataConver.length !== 0) {
            EdgesConver = [
              ...EdgesConver,
              {
                id: `Left_Ref:${number}`,
                source: `${preId}`,
                target: `${number}`,
                type: edgeType,
                animated: true,
              },
            ];
          }
          dataConver = [
            ...dataConver,
            { ...data.node, id: `${number}`, type: "nodeElm" },
          ];
        }
        if (data.left) {
          handleConvertData(data.left, EdgesConver, number + 1, number);
        }
        if (data.right) {
          handleConvertData(data.right, EdgesConver, `R_${number + 1}`, number);
        }
      };
      handleConvertData(nodeTree, EdgesConver, 0, null);
      setNodeData(dataConver);

      setEdges(EdgesConver);
      setLoading(false);
    }
  }, [nodeTree]);
  console.log("afiliteList" , afiliteList);
  
  return (
    <AffilliateWapper>
      <BoxTitlePage>
        <h3>Affiliate</h3>
      </BoxTitlePage>
      <FillterTool>
        <Tabs TabsList={TabsList} value={activeTab} setValue={setActiveTab} />
        <SearchInput>
          <SearchIcon>
            <img src={IconSearch} alt="icon" />
          </SearchIcon>
          <input type="text" placeholder="Search here..." />
        </SearchInput>
      </FillterTool>
      {activeTab?.value === 1 ? (
        !loading ? (
          <TreeNode nodeData={nodeData} Edges={Edges} />
        ) : (
          <></>
        )
      ) : (
        <BlueTable>
          <Table
            data={[...afiliteList.left, ...afiliteList.right]}
            keyObj={[
              {
                th: "Wallet address",
                key: "",
                callback: (data: any) => {
                  if (isMobile) {
                    return (
                      <CopyToClipboard
                        text={`${window.location.origin}/${data.address}`}
                        onCopy={() => {
                          toast.success("Copied");
                        }}
                      >
                        <div>
                          <p>{data?.address?.slice(0, 3)}...{data?.address?.slice(-3)}</p>
                          <figure>
                            <img src="/assets/icon/copy.svg" alt="icon" />
                          </figure>
                        </div>
                      </CopyToClipboard>
                    )
                  } else {
                    return (
                      <p>{data?.address?.slice(0, 3)}...{data?.address?.slice(-3)}</p>
                    )
                  }
                }
                ,
              },
              {
                th: "Sponser",
                key: "Id",
                callback: (data: any) => {
                  if (isMobile) {
                    return (
                      <div>
                        <p><span>Sponsor</span><span>{data?.sponser?.slice(0, 3)}...{data?.sponser?.slice(-3)}</span></p>
                      </div>
                    )
                  } else {
                    return (
                      <p>{data?.sponser?.slice(0, 3)}...{data?.sponser?.slice(-3)}</p>
                    )
                  }
                }
              },
              {
                th: "Total Invest", key: "invest_token",
                callback: (data: any) => {
                  if (isMobile) {
                    return (
                      <div>
                        <p>Total Invest</p>
                        <p>{data?.invest_token}</p>
                      </div>
                    )
                  } else {
                    return (
                      <p>{data?.invest_token}</p>
                    )
                  }
                }
              },
              {
                th: "Ranking",
                key: "ranking",
                callback: (data: any) => {
                  if (isMobile) {
                    return (
                      <div>
                        <p>Ranking</p>
                        <p>{data?.ranking}</p>
                      </div>
                    )
                  } else {
                    return (
                      <p>{data?.ranking}</p>
                    )
                  }
                }
              },
              {
                th: "Level", key: "level",
                callback: (data: any) => {
                  if (isMobile) {
                    return (
                      <div>
                        <p>Level: {data?.level}</p>
                      </div>
                    )
                  } else {
                    return (
                      <p>{data?.level}</p>
                    )
                  }
                }
              },
            ]}
          />
        </BlueTable>
      )}
    </AffilliateWapper>
  );
};
export default Affiliate;

const TabsList = [
  {
    value: 1,
    title: "Community Tree",
  },
  {
    value: 2,
    title: "Affiliate List",
  },
];
