import { useContext, useEffect, useState } from "react";
import { ContextProviderWrapper } from "../../../components/Context";
import { formatNumber } from "../../../utils/formatNumber";
import { BoxTitleRadius } from "../../Dashboard/styled";
import {
  BoxImg,
  BoxStaked,
  BoxTotalDetail,
  BoxTotalStakeElm,
  BoxTotalStakeGroup,
  StakeTONConten,
  YourStakedContainer,
} from "../styled";
import { dispatch, RootState, useSelector } from "../../../store/reDucStore";
import TotalIcon1 from "../../../assets/Staking/TotalIcon1.svg";
import TotalIcon2 from "../../../assets/Staking/TotalIcon2.svg";
import { ProgessContent } from "../../Bonus/styled";
import { InvestStatus } from "../../../store/Slices/invesmens";
import { useTonAddress } from "@tonconnect/ui-react";

const YourStaked = () => {
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const InvestInfo = useSelector(
    (state: RootState) => state.investmens.InvestInfo
  );
  const [valueProgess, setValueProgess] = useState<any>(0);
  const TokenInfo = useSelector((state: RootState) => state.token.TokenInfo);
  const dashboardList = useSelector(
    (state: RootState) => state.dashboard.dashBoardInfo
  );
  const StatusInvest = useSelector(
    (state: RootState) => state.investmens.StatusInvest
  );
  const address = useTonAddress();
  useEffect(() => {
    if (
      dashboardList?.overview?.max_out_earning &&
      InvestInfo?.invested?.total_vesting?.token
    ) {
      setValueProgess(
        (InvestInfo?.invested?.total_vesting?.token /
          dashboardList?.overview?.max_out_earning) *
          100
      );
    }
  }, [dashboardList, InvestInfo]);
  console.log("StatusInvest" , StatusInvest);
  

  const fetchInvestmentStatus = async () => {
    const res = await dispatch(InvestStatus());
  }

  useEffect(() => {
    if (localStorage.getItem("token")) {
      fetchInvestmentStatus()
    }
  }, [localStorage.getItem("token"), address]);

  return (
    <YourStakedContainer>
      <BoxTitleRadius>{!isMobile && <h3>Your Staked</h3>}</BoxTitleRadius>
      <StakeTONConten>
        <BoxStaked>
          <p>Total Staked</p>
          <h3>
            {(InvestInfo?.invested?.total_invested?.token &&
              formatNumber(
                (InvestInfo?.invested?.total_invested?.token)?.toFixed(2)
              )) ||
              0}{" "}
            <span>
              <img src="./assets/ton_symbol.svg" alt="icon" />
            </span>
            {StatusInvest && (
              <span>
                <p style={{ fontSize: '15px' }}>{StatusInvest === "PENDING" && "(In Progress)"}</p>
              </span>
            )}
            
          </h3>
          <h6>
            ~$
            {TokenInfo?.prices?.USD && InvestInfo?.invested?.total_invested?.usd
              ? formatNumber(Number(InvestInfo?.invested?.total_invested?.usd)?.toFixed(2))
              : 0}
          </h6>
          <ProgessContent value={valueProgess}>
            <div style={{ maxWidth: "100%", padding: "5px" }}>
              <p>
                Max Out Earning:{" "}
                {dashboardList?.overview?.max_out_earning
                  ? new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                    }).format(
                      dashboardList?.overview?.max_out_earning?.toFixed(2)
                    )
                  : "0"}
              </p>
            </div>
          </ProgessContent>
        </BoxStaked>
        <BoxTotalStakeGroup>
          <BoxTotalStakeElm>
            <BoxImg>
              <img src={TotalIcon1} alt="icon" />
            </BoxImg>
            <BoxTotalDetail>
              <p>Your Vesting</p>
              <h3>
                {formatNumber(InvestInfo?.invested?.total_vesting?.token?.toFixed(2)) || 0}{" "}
                <img src="./assets/ton_symbol.svg" alt="ton_symbol" />
                {StatusInvest && (
                  <p style={{ fontSize: '15px' }}>{StatusInvest === "PENDING" && "(In Progress)"}</p>
                )}
                
              </h3>
              <p>~$ {formatNumber(InvestInfo?.invested?.total_vesting?.usd?.toFixed(2)) || 0}</p>
            </BoxTotalDetail>
          </BoxTotalStakeElm>
          <BoxTotalStakeElm>
            <BoxImg>
              <img src={TotalIcon2} alt="icon" />
            </BoxImg>
            <BoxTotalDetail>
              <p>Time Staked</p>
              <h3>{InvestInfo?.invested?.time_invested || 0} Days</h3>
            </BoxTotalDetail>
          </BoxTotalStakeElm>
        </BoxTotalStakeGroup>
      </StakeTONConten>
    </YourStakedContainer>
  );
};

export default YourStaked;
