import {
  PoolAddress,
  PoolInformation,
  StakingTitle,
  StakingWapper,
  InformationBox,
  StakingTitlePoll,
  StakingSwitchTabs,
} from "./styled";
import { BoxTitleRadius, BoxValue, IconBox } from "../Dashboard/styled";
import { useContext, useEffect, useState } from "react";
import { MenuContext } from "../../Provider/MenuProvider";
import { RootState, useDispatch, useSelector } from "../../store/reDucStore";
import { getTokenInfo } from "../../store/Slices/Token";
import { getAddresAdmin, getInvestInfo } from "../../store/Slices/invesmens";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import CardIcon1 from "../../assets/Dashboard/CardIcon1.svg";
import CardIcon2 from "../../assets/Dashboard/CardIcon2.svg";
import CardIcon3 from "../../assets/Dashboard/CardIcon3.svg";
import CardIcon4 from "../../assets/Dashboard/CardIcon4.svg";
import StakeTon from "./StakeTon";
import YourStaked from "./YourStaked";
import { ContextProviderWrapper } from "../../components/Context";
import { formatNumber } from "../../utils/formatNumber";
import { getDasboardInfo } from "../../store/Slices/Dashboard";

const Staking = () => {
  const { setTitle, setDes }: any = useContext(MenuContext);
  const [tonConnectUI] = useTonConnectUI();
  const address = useTonAddress();

  useEffect(() => {
    setTitle("Staking");
    setDes("");
  }, []);

  const dispatch = useDispatch();
  // @ts-ignore
  const currentWalletInfo = tonConnectUI.walletInfo;
  const adminAddress = useSelector(
    (state: RootState) => state.investmens.adminAddress
  );
  const TokenInfo = useSelector((state: RootState) => state.token.TokenInfo);
  const InvestInfo = useSelector(
    (state: RootState) => state.investmens.InvestInfo
  );

  useEffect(() => {
    if (localStorage.getItem("token") && address) {
      dispatch(getTokenInfo());
      dispatch(getInvestInfo());
      dispatch(getAddresAdmin());
      dispatch(getDasboardInfo());
    }
  }, [localStorage.getItem("token"), address]);

  const [stateSwitch, setStateSwitch] = useState(1);
  const { isMobile } = useContext(ContextProviderWrapper)!;
  const handleSwitchTabMobile = () => {
    switch (stateSwitch) {
      case 1:
        return <StakeTon />;
      case 2:
        return <YourStaked />;
    }
  };

  return (
    <StakingWapper>
      <StakingTitle>
        <StakingTitlePoll>
          <div>
            <h3>Easy to Stake</h3>
            <h6>
              Begin your staking with just 1 TON and start earning with ease
            </h6>
          </div>
          <div>
            <p>Pool Address</p>
            <PoolAddress>
              <h6>
                {/* {adminAddress?.address_invest
                  ? `${adminAddress.address_invest?.slice(
                      0,
                      4
                    )}...${adminAddress.address_invest?.slice(-4)}`
                  : ""} */}
                TBA
              </h6>
              <button>Copy</button>
            </PoolAddress>
          </div>
        </StakingTitlePoll>
        <PoolInformation>
          <BoxTitleRadius>
            <h3>Pool Information</h3>
          </BoxTitleRadius>
          <InformationBox>
            <IconBox>
              <img src={CardIcon1} alt="icon" />
            </IconBox>
            <BoxValue>
              <p>Total Staked TON</p>
              <h3>
                {InvestInfo?.dashboard?.totalInvestedToken ||
                InvestInfo?.dashboard?.totalInvestedToken === 0
                  ? formatNumber(
                      (InvestInfo?.dashboard?.totalInvestedToken + 132542.65)?.toFixed(2)
                    )
                  : "0"}

                <img src="./assets/ton_symbol.svg" alt="avatar" />
              </h3>
              <p>
                ~$ {TokenInfo?.prices?.USD ? InvestInfo?.dashboard?.totalInvestedToken ||
                InvestInfo?.dashboard?.totalInvestedToken === 0
                  ? formatNumber(
                      (InvestInfo?.dashboard?.totalInvestedToken + 132542.65 * TokenInfo?.prices?.USD)?.toFixed(2)
                    )
                  : "0" : "--"}
              </p>
            </BoxValue>
          </InformationBox>
          <InformationBox>
            <IconBox>
              <img src={CardIcon2} alt="icon" />
            </IconBox>
            <BoxValue>
              <p>Total Staker</p>
              <h3>
                {InvestInfo?.dashboard?.totalStaker ||
                InvestInfo?.dashboard?.totalStaker === 0
                  ? formatNumber((InvestInfo?.dashboard?.totalStaker + 2649)?.toFixed(2))
                  : 0}
              </h3>
              
            </BoxValue>
          </InformationBox>
          <InformationBox>
            <IconBox>
              <img src={CardIcon3} alt="icon" />
            </IconBox>
            <BoxValue>
              <p>TON Total Supply</p>
              <h3>
                {InvestInfo?.dashboard?.totalSupplyToken ||
                InvestInfo?.dashboard?.totalSupplyToken === 0
                  ? formatNumber(
                      (InvestInfo?.dashboard?.totalSupplyToken + 232623.76)?.toFixed(2)
                    )
                  : "0"}
                <img src="./assets/ton_symbol.svg" alt="avatar" />
              </h3>
              <p>
                ~$ {TokenInfo?.prices?.USD ? InvestInfo?.dashboard?.totalSupplyToken ||
                InvestInfo?.dashboard?.totalSupplyToken === 0
                  ? formatNumber(
                      (InvestInfo?.dashboard?.totalSupplyToken + 232623.76 * TokenInfo?.prices?.USD)?.toFixed(2)
                    )
                  : "0" : "--"}
              </p>
            </BoxValue>
          </InformationBox>
          <InformationBox>
            <IconBox>
              <img src={CardIcon4} alt="icon" />
            </IconBox>
            <BoxValue>
              <p>APR</p>
              <h3>{InvestInfo?.dashboard?.apr_percent || 0}%</h3>
            </BoxValue>
          </InformationBox>
        </PoolInformation>
      </StakingTitle>
      {isMobile && (
        <StakingSwitchTabs>
          {tabData.map((item, index) => {
            return (
              <div
                style={{
                  backgroundColor:
                    item.id === stateSwitch ? "#0085FF" : "transparent",
                  color: item.id === stateSwitch ? "#fff" : "#878EA0",
                }}
                onClick={() => {
                  setStateSwitch(item.id);
                }}
                key={index}
              >
                {item.text}
              </div>
            );
          })}
        </StakingSwitchTabs>
      )}
      <StakingTitle typeRever={true}>
        {!isMobile ? (
          <>
            <StakeTon />
            <YourStaked />
          </>
        ) : (
          <>{handleSwitchTabMobile()}</>
        )}
      </StakingTitle>
    </StakingWapper>
  );
};

const tabData = [
  {
    id: 1,
    text: "Staked TON",
  },
  {
    id: 2,
    text: "Your Staked",
  },
];

export default Staking;
