import { Link } from "react-router-dom";
import {
  HeaderContainer,
  HeaderContainerFix,
  HeaderDetail,
  HeaderTitle,
  Rings,
} from "./styled";
import { LangData } from "../Contant";
import { useContext, useEffect, useLayoutEffect, useState } from "react";
import BtnConnect from "../buttonConnect";
import styled from "styled-components";
import { MenuContext } from "../../Provider/MenuProvider";
import { ContextProviderWrapper } from "../Context";
import { useTonAddress, useTonConnectUI,
  useTonWallet, } from "@tonconnect/ui-react";

const Header = () => {
  const [languageValue, setLanguageValue] = useState(LangData[0]);
  const { title , des} = useContext(MenuContext);
  const { isMobile } = useContext(ContextProviderWrapper)!
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonWallet();
  const address = useTonAddress();
  
  return (
    <HeaderContainer>
      <HeaderContainerFix>
        <HeaderTitle>
          <h3>{title}</h3>
          {/* {des && <p>{des}</p>} */}
        </HeaderTitle>
        <BoxLogo>
          <Link to="">
            <div>
              <img src="./assets/LogoTon.svg" alt="" />
              {!isMobile && <img src="./assets/logoOnlyText.svg" alt="" />}
            </div>
          </Link>
        </BoxLogo>
        <HeaderDetail>
          <BtnConnect />
          <Rings className="avatar">
            <img src="./assets/defaut-avatar.png" alt="avatar" />
          </Rings>
        </HeaderDetail>
      </HeaderContainerFix>
    </HeaderContainer>
  );
};

export default Header;

const BoxLogo = styled.div`
  a {
    display: flex;
    div {
      display: flex;
      gap: 5px;
    }
  }
  @media only screen and (min-width: 991px) {
    display: none;
  }
  @media only screen and (max-width: 768px) {
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      div {
        img {
          max-height: 100%;
          &:last-child {
            max-height: 80%;
          }
        }
      }
    }
  }
`;
