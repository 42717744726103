import styled from "styled-components";

export const TextStatus = styled.div`
  border-radius: 16px;
  background: rgba(0, 255, 56, 0.15);
  color: #17F147;
  font-size: 13px;
  width: max-content;
  padding: 5px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  @media screen and (max-width: 767px) {
    margin-right: auto;
    margin-left: 0;
  }
`