import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ENDPOINT, { PRODUCT } from "../../services/Endpoint";
import type { FulfilledAction, PendingAction, RejectedAction } from "../utils/utils";
import { instance } from "../instance";

const initialState:any = {
  NetwordInfo: null,
  loading: false,
  nodeTree: null,
  afiliteList:null
};
export const getNetwordInfo = createAsyncThunk(`${PRODUCT}/NetwordInfo`, async (thunkAPI) => {
  try {
    const response = await instance.get(ENDPOINT.networdInfo, {
    });
    return response;
  } catch (ex: any) {
    return ex;
  }
});
export const getAffiliateList = createAsyncThunk(`${PRODUCT}/AffiliateList`, async (thunkAPI) => {
  try {
    const response = await instance.get(ENDPOINT.afiliteList, {
    });
    return response;
  } catch (ex: any) {
    return ex;
  }
});

export const getNetwordTree = createAsyncThunk(`${PRODUCT}/NetwordTree`, async (thunkAPI) => {
  try {
    const response = await instance.get(ENDPOINT.nodeTree, {
    });
    return response;
  } catch (ex: any) {
    return ex;
  }
});


const networdSlice = createSlice({
  name: "Netword",
  initialState,
  reducers: {
    //
  },
  extraReducers(builder) {
    builder
      .addCase(getNetwordInfo.fulfilled, (state, action) => {
        if (action.payload?.status === 200) {
          state.NetwordInfo = action.payload.data;
        } else {
          state.NetwordInfo = null;
        }
      })
      .addCase(getAffiliateList.fulfilled, (state, action) => {
        if (action.payload?.status === 200) {
          state.afiliteList = action.payload.data;
        } else {
          state.afiliteList = null;
        }
      })
      .addCase(getNetwordTree.fulfilled, (state, action) => {
        if (action.payload?.status === 200) {
          state.nodeTree = action.payload.data;
        } else {
          state.nodeTree = null;
        }
      })
      .addMatcher<PendingAction>(
        (action) => action.type.endsWith("/pending"),
        (state, action) => {
          state.loading = true;
          state.currentRequestId = action.meta.requestId;
        }
      )
      .addMatcher<RejectedAction | FulfilledAction>(
        (action) => action.type.endsWith("/rejected") || action.type.endsWith("/fulfilled"),
        (state, action) => {
          if (state.loading && state.currentRequestId === action.meta.requestId) {
            state.loading = false;
            state.currentRequestId = null;
          }
        }
      );
  },
});

const networdReducer = networdSlice.reducer;

export default networdReducer;
