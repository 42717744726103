import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ENDPOINT, { PRODUCT } from "../../services/Endpoint";
import type { FulfilledAction, PendingAction, RejectedAction } from "../utils/utils";
import { instance } from "../instance";

const initialState:any = {
  InvestInfo:null,
    Submit: null,
  loading: false,
  adminAddress:null,
  StatusInvest : null,
};

export const getInvestInfo = createAsyncThunk(`${PRODUCT}/getInvestInfo`, async ( param ,thunkAPI) => {
  try {
    const response = await instance.get(ENDPOINT.InvestInfo , {
    });
    return response;
  } catch (ex: any) {
    return ex;
  }
});
export const getAddresAdmin = createAsyncThunk(`${PRODUCT}/getAddresAdmin`, async ( param ,thunkAPI) => {
  try {
    const response = await instance.get(ENDPOINT.getAdress , {
    });
    return response;
  } catch (ex: any) {
    return ex;
  }
});

export const SubmitInvest = createAsyncThunk(`${PRODUCT}/SubmitInvest`, async ( param ,thunkAPI) => {
  try {
    const response = await instance.post(ENDPOINT.submitInvest, param , {
    });
    return response;
  } catch (ex: any) {
    return ex;
  }
});

export const InvestStatus = createAsyncThunk(`${PRODUCT}/InvestStatus`, async (param, thunkAPI) => {
  try {
    const response = await instance.get(ENDPOINT.investStatus);
    return response;
  } catch (ex: any) {
    return ex;
  }
})


const InvestSlice = createSlice({
  name: "Invest",
  initialState,
  reducers: {
    //
  },
  extraReducers(builder) {
    builder
      .addCase(SubmitInvest.fulfilled, (state, action) => {
        console.log(action.payload)
        if (action.payload?.status === 200) {
          state.Submit = action.payload;
        } else {
          state.Submit = null;
          // window.location.reload();
        }
      })
      .addCase(getAddresAdmin.fulfilled, (state, action) => {
        if (action.payload?.status === 200) {
          state.adminAddress = action.payload.data;
        } else {
          state.adminAddress = null;
          // window.location.reload();
        }
      })
      .addCase(getInvestInfo.fulfilled, (state, action) => {
        if (action.payload?.status === 200) {
          state.InvestInfo = action.payload.data;
        } else {
          state.InvestInfo = null;
          // window.location.reload();
        }
      })
      .addCase(InvestStatus.fulfilled, (state, action) => {
        console.log("action.payload" , action.payload)
        state.StatusInvest = action.payload?.data?.status
      })
      .addMatcher<PendingAction>(
        (action) => action.type.endsWith("/pending"),
        (state, action) => {
          state.loading = true;
          state.currentRequestId = action.meta.requestId;
        }
      )
      .addMatcher<RejectedAction | FulfilledAction>(
        (action) => action.type.endsWith("/rejected") || action.type.endsWith("/fulfilled"),
        (state, action) => {
          if (state.loading && state.currentRequestId === action.meta.requestId) {
            state.loading = false;
            state.currentRequestId = null;
          }
        }
      );
  },
});

const InvestReducer = InvestSlice.reducer;

export default InvestReducer;
