import { configureStore } from "@reduxjs/toolkit";
import { dexApiV1 } from "./api/dexApiSlice";
import { authApiV1 } from "./api/authApiSlice";
import { authReducer } from "./features/authSlice.ts";
import { accountApiV1 } from "./api/accountApiSlice";
// import { themeReducer } from "./features/themeSlice";
import {
  useDispatch as useAppDispatch,
} from 'react-redux';
import poolReducer from "./features/poolsSlice";
import assetsReducer from "./features/assetsSlice";
import dashboardReducer from "./Slices/Dashboard";
import networdReducer from "./Slices/NetWord";
import TokenReducer from "./Slices/Token";
import TransitionReducer from "./Slices/Transition";
import InvestReducer from "./Slices/invesmens";
import WidthrawReducer from "./Slices/Withdraw";
import UserReducer from "./Slices/User";

export const store = configureStore({
  reducer: {
    [dexApiV1.reducerPath]: dexApiV1.reducer,
    [authApiV1.reducerPath]: authApiV1.reducer,
    [accountApiV1.reducerPath]: accountApiV1.reducer,
    auth: authReducer,
    pool: poolReducer,
    assets: assetsReducer,
    dashboard: dashboardReducer,
    network: networdReducer,
    token: TokenReducer,
    Transition : TransitionReducer,
    investmens: InvestReducer,
    withdraw: WidthrawReducer,
    User: UserReducer,
  },
  middleware: (getDefaultMiddleware:any) =>
    getDefaultMiddleware()
      .concat(dexApiV1.middleware)
      .concat(authApiV1.middleware)
      .concat(accountApiV1.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const useDispatch = () => useAppDispatch<AppDispatch>();

export { useDispatch };
