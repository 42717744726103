import styled from "styled-components";
import { motion } from "framer-motion"

export const SidebarContainer = styled.div<{ isshow?: any }>`
  width: 100%;
  max-width: ${({ isshow }) => (isshow ? "260px" : "64px")};
  padding: ${({ isshow }) =>
    isshow ? "28px 8px 28px 30px" : "28px 8px 28px 8px"};
  height: 100%;
  background-color: rgba(27, 28, 32, 1);
  /* overflow:hidden; */
  transition: all 0.3s linear;
  position: relative;
  z-index: 101;
  @media only screen and (max-width: 991px) {
    display: none;
  }
`;
export const SidebarHeader = styled.div<{ isshow?: any }>`
  display: flex;
  align-items: center;
  justify-content:  ${({ isshow }) => (isshow ? "space-between" : "center")};
  position: relative;
  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: ${({ isshow }) => (isshow ? "space-between" : "center")};
    gap: 10px;
    img {
      &:last-child {
        transform: scale(${({ isshow }) => (isshow ? 1 : 0)});
        transition: all 0.3s ease;
        position: ${({ isshow }) => (isshow ? "relative" : "absolute")};
      }
    }
  }
  button {
    background: transparent;
    border: none;
    width: 32px;
    height: 32px;
    transition: all 0.3s ease;
    ${({ isshow }) =>
      isshow
        ? `
    position: relative;
    `
        : `
    position: absolute;
    right: -8px;
    top: 50%;
    transform: translateX(50%) translateY(-50%) rotateZ(180deg);
    z-index: 2;
    `};
  }
`;
export const SideBarContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 82px;
  height: calc(100% - 38px);
`;
export const SideBarLink = styled.div<{ isshow?: any }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  a {
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    padding: 5px;
    border-radius: 34px;
    transition: all 0.3s ease;
    img{
      width: 22px;
    }
    p {
      font-family: "AnekLatin-Regular";
      font-weight: 500;
      font-size: 17px;
      line-height: 120%;
      font-weight: 500;
      color: #878ea0;
      display: inline-block;
      align-items: center;
      opacity: ${({ isshow }) => (isshow ? 1 : 0)};
      transform: scale(${({ isshow }) => (isshow ? 1 : 0)});
      transition: all 0.3s ease;
      white-space: nowrap;
    }
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      aspect-ratio: 1;
      border-radius: 50%;
    }
  }
  .active {
    background-color: #cce7ff;
    p {
      color: #0085ff;
    }
    div {
      background-color: #0085ff;
    }
    img {
      filter: brightness(255);
    }
  }
`;

export const LogoutButton = styled.button<{ isshow?: any }>`
  display: flex;
  align-items: center;
  border: none;
  gap: ${({ isshow }) => (isshow ? "15px" : 0)};
  background: transparent;
  padding: 13px;
  font-family: anek-odia-700-normal;
  font-size: 17px;
  line-height: 120%;
  font-weight: 900;
  color: rgba(255, 255, 255, 0.7);
  transition: all 0.3s ease;
  /* justify-content: ${({ isshow }) => (isshow ? "flex-start" : "center")}; */
  max-width: 100%;
  p {
    opacity: ${({ isshow }) => (isshow ? 1 : 0)};
    transform: scale(${({ isshow }) => (isshow ? 1 : 0)});
    transition: all 0.3s ease;
  }
`;
export const DragSpan = styled(motion.div)`
  width: 64px;
  height: 5px;
  margin: 0 auto;
  border-radius: 100px;
  background: #FFF;
  margin-bottom: 20px;
`