import { Outlet } from "react-router-dom";
import { ContentWapper, LayoutContainer } from "./styled";
import Header from "../components/Header";
import Sidebar from "../components/sidebar";
import { useContext, useState } from "react";
import styled from "styled-components";
import { MenuContext } from "../Provider/MenuProvider";
import NavigationMoible from "../components/sidebar/mobile";
import { ContextProviderWrapper } from "../components/Context";

const LayoutCommon = () => {
  const { isOpen } = useContext(MenuContext);
  const { isMobile , isDesktop } = useContext(ContextProviderWrapper)!
  const [isMenuMobile, setIsMenuMobile] = useState(false)
  const [openSiderBar , setOpenSiderBar] = useState(false)

  return (
    <LayoutContainer>
      <Sidebar isMenuMobile={isMenuMobile} setIsMenuMobile={setIsMenuMobile} setOpenSiderBar={setOpenSiderBar} />
      <NavigationMoible setIsMenuMobile={setIsMenuMobile} />
      <ContentWapper opensiderbar={isDesktop && openSiderBar}>
        <Header />
        <ContentLayOut hiden={isOpen}>
          <Outlet />
        </ContentLayOut>
      </ContentWapper>
    </LayoutContainer>
  );
};

export default LayoutCommon;
const ContentLayOut = styled.div<{ hiden?: any }>`
  width: 100%;
  height: calc(100% - 108px);
  overflow-x: ${({ hiden }) => (hiden ? "hidden" : "auto")};
  @media only screen and (max-width: 768px) {
    height: calc(100% - 68px);
  }
`;
