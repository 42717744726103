import styled from "styled-components";
import dost from "../../assets/Withdraw/Dots.png"
import dost2 from "../../assets/Withdraw/Dost2.png"

export const RoadMapContent = styled.div`
  width: 100%;
  display: flex;
  margin-top: 30px;
  padding-top: 32px;
  border-top: 1px solid rgba(68, 71, 80, 1);
  gap: 20px;
  position: relative;
  &::after {
    width: 5%;
    height: 0px;
    content: "";
    position: absolute;
    top: -1px;
    right: 0;
    border-top: 1px dashed rgba(0, 0, 0, 1);
    z-index: 2;
  }
`;
export const BoxElm = styled.div<{active?:any}>`
  width: calc((100% - 60px) / 4);
  align-self: stretch;
  padding: 20px;
  border-radius: 17.858px;
  /* border: 1.191px solid #fff; */
  background: rgba(204, 231, 255, 0.15);
  backdrop-filter: blur(7.793497562408447px);
  display: flex;
  flex-direction: column;
  gap: 19px;
  background-position: center;
  transition: background 0.3s linear;
  position: relative;
  z-index: 1;
  &::before{
    content: "";
    width: 16px;
    height: 16px;
    background-image: url(${({active}) => active ? dost : dost2});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top:-32px;
    left: 10%;
    transform: translateY(-50%);
  }
  h1 {
    color: var(--Content-content-primary, #f0f0f2);
    font-family: "AnekLatin-Regular";
    font-size: 29.573px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 37.145px */
  }
  span {
    width: 100%;
    height: 1px;
    display: inline-block;
    background: #76767d;
    transition: background 0.3s linear;
  }
  ul {
    display: flex;
    flex-direction: column;
    gap: 12px;
    li {
      display: flex;
      align-items: flex-start;
      span {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: var(--Content-content-primary, #f0f0f2);
        margin-top: 8px;
      }
      p {
        color: var(--Content-content-primary, #f0f0f2);
        font-family: "AnekLatin-Regular";
        font-size: 20.049px;
        font-style: normal;
        font-weight: 400;
        line-height: 132%; /* 25.144px */
        letter-spacing: -0.381px;
      }
    }
  }
  &:hover {
    background: radial-gradient(
      87.43% 87.43% at 50% 50%,
      rgba(0, 87, 255, 0.5) 0%,
      rgba(0, 133, 255, 0) 100%
    );
    background-size: cover;
    backdrop-filter: blur(6.010456562042236px);
    span {
      background: var(--Blue-Base, #0085ff);
    }
  }
`;
