import styled from "styled-components";
import Bg from "../../assets/Withdraw/CharBlur.png";
export const InfoConten = styled.div`
  display: flex;
  padding: 24px;
  gap: 40px;
  align-items: center;
  div {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: calc((100% - 81px) / 2);
    h3 {
      color: var(--Gray-30, #c3c6cf);
      font-family: "AnekLatin-Regular";
      font-size: 19px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 88.889% */
      letter-spacing: 0.18px;
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      p {
        color: #fff;
        font-family: "AnekLatin-Regular";
        font-size: 19px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 88.889% */
        letter-spacing: 0.18px;
      }
    }
  }
  span {
    display: flex;
    align-self: stretch;
    width: 1px;
    background-color: #878ea0;
  }
`;

export const TokenomicsConten = styled.div`
  width: 100%;
  padding: 100px 0px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${Bg});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

export const TokenomicsContenChrt = styled.div`
  position: relative;
  overflow: hidden;
  z-index: 1;
`;

export const TokenomicsValue = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-end;
  position: relative;
  &:last-child{
    align-items: flex-start;
  }
  h3 {
    color: rgba(220, 227, 235, 1);
    color: var(--Light-Gray-70, #93979d);
    font-family: "AnekLatin-Regular";
    font-weight: 400;
    font-size: 33px;
    line-height: 44.818px; /* 224.091% */
    position: absolute;
    display: flex;
    padding: 0px 30px;
    span {
      color: rgba(147, 151, 157, 1);
      font-size: 21px;
    }
    &:nth-child(2){
      top: 38%;
    }
    &:nth-child(3){
      top: 62%;
    }
    &:last-child{
      bottom: 0px;
    }
  }
`;
