import styled from "styled-components";
import { BoxSelect } from "../inputSelect";

export const HeaderContainer = styled.div`
  width: 100%;
  height: 108px;
  @media only screen and (max-width: 768px) {
    height: 68px;
  }
`;
export const HeaderContainerFix = styled.div`
  width: calc(100% - 260px);
  display: flex;
  align-items: center;
  padding: 34px 24px 26px 24px;
  justify-content: space-between;
  position: fixed;
  z-index: 100;
  background-color: #000;
  @media only screen and (max-width: 991px) {
    width: 100%;
  }
  @media only screen and (max-width: 768px) {
    align-items: center;
    padding: 10px 16px;
    background: #002C55;
    backdrop-filter: blur(6.75px);
    .avatar {
      display: none;
    }
  }
`;

export const HeaderTitle = styled.div`
  h3 {
    font-family: "anek-odia-600-normal";
    color: #fff;
    font-size: 34px;
    line-height: 100%;
  }
  p {
    font-family: AnekLatin-Regular;
font-weight: 500;
    color: var(--Gray-Base, #878ea0);
    font-size: 15px;
    line-height: 100%;
  }
  @media only screen and (max-width: 991px) {
    display: none;
  }
  @media only screen and (max-width: 768px) {
    h3 {
      font-size: 25px;
    }
    p {
      font-size: 13px;
    }
  }
`;
export const HeaderDetail = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: flex-end;
  @media only screen and (max-width: 768px) {
    gap: 4px;
    ${BoxSelect} {
      display: none;
    }
  }
`;
export const Rings = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    width: 30px;
    height: 30px;
    img {
      max-width: 100%;
    }
  }
`;
