import { PannerNetword } from "../Network/style";
import { StakingWapper } from "../Staking/styled";
import Bg from "../../assets/Withdraw/BgRoadmap.png";
import Icon from "../../assets/Withdraw/RoadmapIcon.png";
import { BoxElm, RoadMapContent } from "./styled";

const RoadMapPage = () => {
  return (
    <StakingWapper>
      <PannerNetword bg={Bg}>
        <div>
          <h3>What’s TON Holding ?</h3>
          <p>
            TON Holding is a liquid staking protocol built on the TON (The Open
            Network) blockchain.{" "}
          </p>
        </div>
        <div className="Icon">
          <img src={Icon} />
        </div>
      </PannerNetword>
      <RoadMapContent>
        <BoxElm active={true}>
          <h1>Phase 1</h1>
          <span></span>
          <ul>
            <li>
              <span></span>
              <p>Researching Market</p>
            </li>
            <li>
              <span></span>
              <p>Competitor Analysis</p>
            </li>
            <li>
              <span></span>
              <p>Setting Up Website</p>
            </li>
            <li>
              <span></span>
              <p>Building Product</p>
            </li>
          </ul>
        </BoxElm>
        <BoxElm active={true}>
          <h1>Phase 2</h1>
          <span></span>
          <ul>
            <li>
              <span></span>
              <p>Developing & Testing Staking</p>
            </li>
            <li>
              <span></span>
              <p>Improving UI</p>
            </li>
            <li>
              <span></span>
              <p>Performing Security Audits</p>
            </li>
            <li>
              <span></span>
              <p>Completing GTM Strategy</p>
            </li>
          </ul>
        </BoxElm>
        <BoxElm>
          <h1>Phase 3</h1>
          <span></span>
          <ul>
            <li>
              <span></span>
              <p>Establishing The Staking on TON Blockchain</p>
            </li>
            <li>
              <span></span>
              <p>Running The TON Holding Funding</p>
            </li>
            <li>
              <span></span>
              <p>Releasing stakeDAO</p>
            </li>
            <li>
              <span></span>
              <p>ICO</p>
            </li>
            <li>
              <span></span>
              <p>Collaborating Partners</p>
            </li>
          </ul>
        </BoxElm>
        <BoxElm>
          <h1>Phase 4</h1>
          <span></span>
          <ul>
            <li>
              <span></span>
              <p>Adding Liquidity to DEXs On TON Blockchain</p>
            </li>
            <li>
              <span></span>
              <p>Improving Product</p>
            </li>
            <li>
              <span></span>
              <p>Releasing Cross-chain Bridge</p>
            </li>
            <li>
              <span></span>
              <p>Releasing NFT Game</p>
            </li>
          </ul>
        </BoxElm>
      </RoadMapContent>
    </StakingWapper>
  );
};
export default RoadMapPage;
