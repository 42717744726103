import styled from "styled-components";
import { BoxChangeValue } from "../pages/Dashboard/styled";
import { motion } from "framer-motion";

export const CollapseButton = styled.div<{ collapse?: boolean }>`
  position: absolute;
  right: -10px;
  top: 50%;
  z-index: 20;
  cursor: pointer;
  transform: ${({ collapse }) => (collapse ? "rotate(0)" : "rotate(180deg)")};
  transition: all 0.25s;
`;
export const SideBarMain = styled(motion.div) <{ collapse?: boolean, IsMenuMobile?: boolean }>`
  width: ${({ collapse }) => (collapse ? "130px" : "260px")};
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  /* transition: all 0.25s ease 0s; */
  &.main-sidebar {
    padding: ${({ collapse }) =>
    collapse ? "15px 25px 15px 15px" : "15px 20px"};
  }
  .list-menu {
    li {
      border-radius: ${({ collapse }) => (collapse ? "13px" : "43px")};
      overflow: hidden;
    }
  }
  .main-price {
    .box-price {
      display: flex;
      align-items: center;
      border-radius: ${({ collapse }) => (collapse ? "13px" : "43px")};
      justify-content: space-between;
      gap: 3px;
      flex-direction: ${({ collapse }) => (collapse ? "column" : "row")} ;
      /* width: ${({ collapse }) => (collapse ? "unset" : "fit-content")}; */
    }
    .img img {
      /* margin: ${({ collapse }) =>
    collapse ? "0 auto 10px" : "0 10px 0 0"}; */
    }
  }
  .list-menu {
    & > li {
      transition: all 0.25s ease 0s;
      font-size: ${({ collapse }) => (collapse ? "0" : "16px")};
    }
    p {
      transform: scale(${({ collapse }) => (collapse ? "0" : "1")});
      position: ${({ collapse }) => (collapse ? "absolute" : "relative")};
      margin-left: 10px;
    }
  }
  .main-menu-row {
    .row-logo {
      padding-right: ${({ collapse }) => (collapse ? "0" : "20px")};
      img {
        /* width: ${({ collapse }) => (collapse ? "45px" : "180px")}; */
        margin-bottom: ${({ collapse }) => (collapse ? "10px" : "0")};
      }
    }
  }
  .main-menu {
    .list-menu {
      & > li {
        a {
          justify-content: ${({ collapse }) => (collapse ? "center" : "unset")};
        }
        img {
          /* margin-right: ${({ collapse }) => (collapse ? "0" : "10px")}; */
        }
      }
    }
  }
  ${BoxChangeValue}{
    p{
      font-size: 13px;
    }
  }
  @media only screen and (max-width: 1024px) {
    position: fixed;
    left: 50%;
    top: unset;
    bottom: 0;
    width: 100%;
    height: 470px;
    margin-left: -50%;
    height: auto;
    z-index: 1001;
    border-radius: 16px 16px 0px 0px;
    background: #2D2F35;
    transform: ${({ IsMenuMobile }) => IsMenuMobile ? "translateY(0) !important" : "translateY(500px) !important"};
    .row-logo,
    .title-menu,
    ${CollapseButton},
    .main-menu.sub-list,
    .position-banner {
      display: none;
    }
    .list-menu {
      & > li {
        border-radius: 8px;
      }
      a {
        padding: 14px 24px;
      }
    }
  }
`;
export const PriceCoin = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .price {
    color: #fff;
    font-family: "PlusJakartaSans";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
    margin-right: 5px !important ;
  }
`;
export const MainBody = styled.div<{ collapse?: boolean }>`
  width: ${({ collapse }) =>
    collapse ? "calc(100% - 100px)" : "calc(100% - 280px)"};
  transition: all 0.25s;
  @media screen and (max-width: 1200px) {
    width: ${({ collapse }) =>
    collapse ? "calc(100% - 100px)" : "calc(100% - 200px)"};
  }
`;
export const MainHeader = styled.div<{ collapse?: boolean }>`
  width: ${({ collapse }) =>
    collapse ? "calc(100% - 100px)" : "calc(100% - 280px)"};
  transition: all 0.25s;
  @media screen and (max-width: 1200px) {
    width: ${({ collapse }) =>
    collapse ? "calc(100% - 100px)" : "calc(100% - 200px)"};
  }
`;
