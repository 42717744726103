import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ENDPOINT, { PRODUCT } from "../../services/Endpoint";
import type {
  FulfilledAction,
  PendingAction,
  RejectedAction,
} from "../utils/utils";
import { instance } from "../instance";
import { handlerToQery } from "../../utils/ObjToQeryParam";

const initialState: any = {
  TransitionList: null,
  loading: false,
};
export const getTransitionList = createAsyncThunk(
  `${PRODUCT}/Transitionasasasa`,
  async (param: any, thunkAPI) => {
    try {
      const qery = handlerToQery(param)
      const response = await instance.get(`${ENDPOINT.transactions}${qery}`, {});
      return response;
    } catch (ex: any) {
      return ex;
    }
  }
);


const TransitionSlice = createSlice({
  name: "Transition",
  initialState,
  reducers: {
    //
  },
  extraReducers(builder) {
    builder
      .addCase(getTransitionList.fulfilled, (state, action) => {
        if (action.payload?.status === 200) {
          state.TransitionList = action.payload.data;
        } else {
          state.TransitionList = null;
          // window.location.reload();
        }
      })
      .addMatcher<PendingAction>(
        (action) => action.type.endsWith("/pending"),
        (state, action) => {
          state.loading = true;
          state.currentRequestId = action.meta.requestId;
        }
      )
      .addMatcher<RejectedAction | FulfilledAction>(
        (action) =>
          action.type.endsWith("/rejected") ||
          action.type.endsWith("/fulfilled"),
        (state, action) => {
          if (
            state.loading &&
            state.currentRequestId === action.meta.requestId
          ) {
            state.loading = false;
            state.currentRequestId = null;
          }
        }
      );
  },
});

const TransitionReducer = TransitionSlice.reducer;

export default TransitionReducer;
