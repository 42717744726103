import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ENDPOINT, { PRODUCT } from "../../services/Endpoint";
import type {
  FulfilledAction,
  PendingAction,
  RejectedAction,
} from "../utils/utils";
import { instance } from "../instance";

const initialState: any = {
  TokenInfo: null,
  loading: false,
};
export const getTokenInfo = createAsyncThunk(
  `${PRODUCT}/token`,
  async (thunkAPI) => {
    try {
      const response = await instance.get(ENDPOINT.TokenInfo, {});
      return response;
    } catch (ex: any) {
      return ex;
    }
  }
);

export const getBalance = createAsyncThunk(
  `${PRODUCT}/balance`,
  async (thunkAPI) => {
    try {
      const response = await instance.get(ENDPOINT.TokenInfo, {});
      return response;
    } catch (ex: any) {
      return ex;
    }
  }
);

const TokenSlice = createSlice({
  name: "Token",
  initialState,
  reducers: {
    //
  },
  extraReducers(builder) {
    builder
      .addCase(getTokenInfo.fulfilled, (state, action) => {
        console.log(action.payload);
        if (action.payload?.status === 200) {
          state.TokenInfo = action.payload?.data?.rates?.TON;
        } else {
          state.TokenInfo = null;
          // window.location.reload();
        }
      })
      .addMatcher<PendingAction>(
        (action) => action.type.endsWith("/pending"),
        (state, action) => {
          state.loading = true;
          state.currentRequestId = action.meta.requestId;
        }
      )
      .addMatcher<RejectedAction | FulfilledAction>(
        (action) =>
          action.type.endsWith("/rejected") ||
          action.type.endsWith("/fulfilled"),
        (state, action) => {
          if (
            state.loading &&
            state.currentRequestId === action.meta.requestId
          ) {
            state.loading = false;
            state.currentRequestId = null;
          }
        }
      );
  },
});

const TokenReducer = TokenSlice.reducer;

export default TokenReducer;
