import styled from "styled-components";

export const LayoutContainer = styled.div`
  width: 100vw;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  justify-content: flex-end;
  min-height: 100%;
  @media screen and (max-width: 767px) {
    display: block;
    height: auto;
  }
`
export const ButtonCommon = styled.button<{ width: string, background: string, color: string, isArrow?: boolean, borderColor?: string }>`

`
export const ContentWapper = styled.div<{opensiderbar?:any}>`
  width: ${({ opensiderbar }) => (opensiderbar ? "calc(100% - 130px)" : "calc(100% - 260px)")};
  transition: all .3s linear;
  min-height: 100%;
  @media only screen and ( max-width: 1024px ) {
    width: 100%;
  }
  /* overflow: hidden; */
`