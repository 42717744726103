import styled from "styled-components";

export const PaginiContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    gap: 10px;
`
export const TextPagini = styled.div`
    color: #fff;
`
export const BoxPage = styled.div`
    display: flex;
    align-items: center;
    color: #878EA0;
    color: var(--Gray-Base, #878ea0);
    font-family: AnekLatin-Regular;
    font-weight: 500;
    font-size: 21px;
    font-weight: 400;
    line-height: 20px;
`
export const ButtonAction = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    background: none;
    border: none;
    width: 20px;
`