import styled from "styled-components";
import { useState } from "react";
const DropSlelect = ({ active, list, setActive, label }: any) => {
  const [isShow, setIsShow] = useState(false);
  return (
    <BoxSelect isshow={isShow}>
      <BoxActiveValue isshow={isShow}>
        {label && <span>{label}</span>}
        <input
          type="text"
          onFocus={() => {
            setIsShow(true);
          }}
          onBlur={() => {
            setIsShow(false);
          }}
        />
        {active?.icon && (
          <FlastBox>
            <img src={active?.icon} />
          </FlastBox>
        )}
        <p>{active?.label}</p>
        <ArrowIcon src={"./assets/ArowIcon.svg"} />
      </BoxActiveValue>
      <BoxList
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        isshow={isShow}
        len={list.length}
      >
        {list?.map((item: any) => (
          <li
            key={item.value}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setActive && setActive(item);
              setIsShow(false);
            }}
          >
            {item?.icon && (
              <FlastBox>
                <img src={item?.icon} />
              </FlastBox>
            )}
            {item.label}
          </li>
        ))}
      </BoxList>
    </BoxSelect>
  );
};
export default DropSlelect;
export const BoxSelect = styled.div<{ isshow: any }>`
  cursor: pointer;
  width: fit-content;
  position: relative;
  align-self: stretch;
  * {
    vertical-align: middle;
  }
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;
const BoxActiveValue = styled.div<{ isshow?: any }>`
  display: flex;
  align-items: center;
  align-self: stretch;
  cursor: pointer;
  gap: 8px;
  border-radius: ${({ isshow }) => (isshow ? "14px 14px 0px 0px" : "34px")};
  position: relative;
  padding: 4px;
  z-index: 1;
  width: 100%;
  background: var(--Gray-90, #2d2f35);
  input {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0;
    cursor: pointer;
  }
  p {
    font-size: 15px;
    color: #fff;
    font-family: AnekLatin-Regular;
font-weight: 500;
    vertical-align: middle;
    line-height: 100%;
  }
  /* @media only screen and (max-width: 768px) {
    p {
      display: none;
    }
  } */
`;
const FlastBox = styled.div`
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
  }
  /* @media only screen and (max-width: 768px) {
    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      object-fit: cover;
    }
  } */
`;
export const ArrowIcon = styled.img`
  margin-right: 8px;
  /* @media only screen and (max-width: 768px) {
    display: none;
  } */
`;
const BoxList = styled.ul<{ isshow: any; len?: any }>`
  padding: ${({ isshow }) => (isshow ? "8px" : "0px 8px")};
  gap: 8px;
  background: var(--Gray-90, #2d2f35);
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 0px 0px 14px 14px;
  height: ${({ isshow, len }) =>
    isshow ? len * 25 + (len - 1) * 8 + 16 : 0}px;
  transition: height 0.3s linear;
  position: absolute;
  bottom: 0px;
  transform: translateY(100%);
  overflow: hidden;
  li {
    gap: 8px;
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #fff;
    font-family: AnekLatin-Regular;
font-weight: 500;
    vertical-align: middle;
    line-height: 100%;
  }
  /* @media only screen and (max-width: 768px) {
    padding: ${({ isshow }) => (isshow ? "4px" : "0px 4px")};
  } */
`;
