import styled from "styled-components";

export const ModalContent = styled.div`
  border-radius: 16px;
  background: var(--Gray-90, #2d2f35);
  position: relative;
  display: flex;
  padding: 16px;
  padding-top: 25px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
  width: 100%;
  gap: 18px;
  h3 {
    span{
      font-size: 22px;
    }
    leading-trim: both;
    text-edge: cap;
    font-family: AnekLatin-Regular;
    font-size: 24px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    background: linear-gradient(90deg, #fff 51.48%, #2d2f35 171.59%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  p {
    color: #dadde6;
    text-align: center;
    font-family: AnekLatin-Regular;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%; /* 150% */
    letter-spacing: 0.268px;
    max-width: 320px;
  }
`;
export const WalletInfoContent = styled.div`
  ${ModalContent} {
    justify-content: flex-start;
    align-items: flex-start;
    h3 {
      padding-left: 16px;
    }
  }
`;

export const WalletInfo = styled.div`
  border-radius: 8px;
  background: #1B1C20;
  width: 100%;
  padding: 16px 8px;
  p {
    text-align: left;
  }
  & > p {
    color: #80C2FF;
    font-family: "anek-odia-400-normal";
    font-size: 14px;
    letter-spacing: 0.14px;
    margin-bottom: 5px;
  }
`
export const WalletAddressInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background: #444750;
  padding: 4px 4px 4px 16px;
  margin-bottom: 25px;
  & > p {
    &:nth-child(1) {
      color: #CCE7FF;
      font-size: 15px;
    }
    &:nth-child(2) {
      display: flex;
      align-items: center;
      gap: 5px;
      color: #fff;
      font-family: "anek-odia-500-normal";
      border-radius: 8px;
      background: #0085FF;
      padding: 8px;
      cursor: pointer;
      span {
        margin-top: 4px;
        line-height: 1;
      }
      img {
        width: 12px;
        height: 12px;
      }
    }
  }
`
export const WalletBalance = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  & > p {
    &:nth-child(1) {
      color: #878EA0;
      font-family: "anek-odia-500-normal";
      font-size: 16px;
      letter-spacing: 0.16px;
    }
    &:nth-child(2) {
      display: flex;
      align-items: center;
      gap: 10px;
      color: #FFF;
      font-family: "PlusJakartaSans";
      font-size: 32px;
      letter-spacing: 0.48px;
    }
  }
`