import styled from "styled-components";
import { TableContainer } from "../../components/Table";
import { BoxTitleRadius } from "../Dashboard/styled";
import { BoxTitlePage } from "../Staking/styled";
import { BlueTable } from "../Bonus/styled";
import { TabsBtn, TabsContainer } from "../Dashboard/Tabs";

export const AffilliateWapper = styled.div`
  width: 100%;
  padding: 40px 24px 40px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 1660px;
  margin: 0px auto;
  flex: 1;
  height: 100%;
  .react-flow__panel{
    /* .right{ */
      display: none;
    /* } */
  }
  @media screen and (max-width: 767px) {
    padding: 25px 15px 100px;
    height: 100vh;
    ${BoxTitlePage} {
      display: none;
    }
    ${BoxTitleRadius} {
      padding-left: 0;
      display: block;
      margin-bottom: 10px;
      h3 {
        font-size: 20px;
        margin-bottom: 20px;
      }
    }
    ${BlueTable} {
      max-width: 100%;
    }
    ${TableContainer} {
      background-color: #1B1C20;
      padding: 12px;
      table {
        min-width: auto;
      }
      thead {
        display: none;
      }
      tbody {
        display: block;
        tr {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          padding: 10px;
          background: #121315;
          border-radius: 10px;
          &:not(:last-child) {
            margin-bottom: 10px;
          }
          td {
            padding: 0;
            &:nth-child(1) {
              width: 50%;
              margin-bottom: 10px;
              order: 2;
              & > div {
                display: flex;
                gap: 15px;
                padding: 4px 4px 4px 12px;
                align-items: center;
                background: #5a5f6b;
                width: max-content;
                border-radius: 35px;
                background: rgba(0, 133, 255, 0.25);
                figure {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 24px;
                  height: 24px;
                  background-color: #0085ff;
                  border-radius: 50%;
                  img {
                    width: 15px;
                    height: 15px;
                  }
                }
                & > p {
                  &:nth-child(1) {
                    font-size: 13px;
                  }
                }
              }
            }
            &:nth-child(2) {
              width: 100%;
              margin-bottom: 10px;
              order: 3;
              & > div {
                & > p {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  gap: 5px;
                  span {
                    &:nth-child(1) {
                      color: #878ea0;
                      font-size: 16px;
                      text-transform: capitalize;
                    }
                    &:nth-child(2) {
                      font-size: 16px;
                      color: #fff;
                    }
                  }
                }
              }
            }
            &:nth-child(3) {
              order: 2;
              margin-left: auto;
            }
            &:nth-child(3),
            &:nth-child(4) {
              display: flex;
              align-items: center;
              order: 7;
              width: calc(50% - 3px);
              background-color: red;
              border-radius: 8px;
              background: #2d2f35;
              padding: 8px;
              min-height: 60px;
              & > div {
                & > p {
                  display: flex;
                  align-items: center;
                  gap: 5px;
                  &:nth-child(1) {
                    margin-bottom: 7px;
                    color: #878ea0;
                    font-size: 16px;
                    text-transform: capitalize;
                  }
                  &:nth-child(2) {
                    font-size: 16px;
                    color: #dce3eb;
                  }
                }
              }
            }
            &:nth-child(4) {
              order: 4;
            }
            &:nth-child(5) {
              width: 50%;
              order: 2;
              & > div {
                & > p {
                  text-align: right;
                  color: #aad6ff;
                  font-size: 14px;
                  text-transform: capitalize;
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const FillterTool = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media only screen and (max-width: 767px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    ${TabsContainer} {
      background-color: #2D2F35;
      border-radius: 35px;
      width: 100%;
      padding: 4px;
      ${TabsBtn} {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        border: none;
        padding: 15px;
        &.active {
          background-color: #0085FF;
        }
      }
    }
  }
`;
export const SearchInput = styled.div`
  border-radius: 34px;
  background: var(--Gray-80, #444750);
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  max-width: 264px;
  padding: 4px;
  input {
    background: transparent;
    border: none;
    outline: none;
    color: #fff;
    width: calc(100% - 44px);
    &:placeholder {
      color: var(--Gray-Base, #878ea0);
      leading-trim: both;
      text-edge: cap;
      font-family: AnekLatin-Regular;
      font-weight: 500;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  @media screen and (max-width: 767px) {
    max-width: 100%;
    margin-bottom: 15px;
    &.his {
      max-width: unset;
    }
  }
`;
export const SearchIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #000;
`;

export const NodeTreeCTN = styled.div`
  padding-bottom: 5px;
`;
export const NodeTreeElm = styled.div`
  border-radius: 16px;
  border: 0.5px solid var(--Gray-70, #5a5f6b);
  background: var(--Gray-90, #2d2f35);
  padding: 14px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
export const NodeTreeTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const NodeAvatar = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const NodeBtn = styled.div<{bg?:any}>`
  border-radius: 16px;
  background: ${({bg})=> bg ? bg : "#0085ff"};
  display: flex;
  padding: 4px 8px 4px 4px;
  align-items: center;
  gap: 4px;
  div {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  h6 {
    color: #fff;
    leading-trim: both;
    text-edge: cap;
    font-family: AnekLatin-Regular;
    font-weight: 500;
    font-size: 13px;
    font-weight: 400;
    line-height: 100%;
  }
`;

export const NodeTreeContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  h3 {
    color: var(--Light-Gray-10, #f8f9fb);
    font-family: AnekLatin-Regular;
    font-weight: 500;
    font-size: 19px;
    font-weight: 400;
    line-height: 100%; /* 77.778% */
  }
  p {
    color: var(--Gray-40, #afb4c0);
    font-family: "PlusJakartaSans";
    font-size: 14px;
    font-weight: 400;
    line-height: 100%; /* 107.692% */
  }
`;
export const Toll = styled.div`
    position: absolute;
    top: 100%;
    left: 50%;
    z-index: 12;
    border-radius: 16px;
    background-color: #fff;
    transform: translateX(-50%);
    z-index: 1000;
    display: none;
    flex-direction: column;
    padding: 16px;
    gap: 5px;
    opacity: 0;
    width: 0px;
    transition: opacity 0.3s linear;
`
export const TextTollBox = styled.div`
  display: flex;
  width: 300px;
  gap: 20px;
  justify-content: space-between;
  p {
    color: #000;
    font-family: AnekLatin-Regular;
    font-weight: 500;
    font-size: 17px;
    font-weight: 400;
    line-height: 100%;
    width: calc(50% - 10px);
    word-break: break-word;
  }
`;
export const AffiliateBody = styled.div`
  padding: 12px;
  border-radius: 16px;
  background: #1b1c20;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
