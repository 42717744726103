import { useEffect, useState } from "react";
import { BoxPage, ButtonAction, PaginiContainer, TextPagini } from "./styled";
import arrow from "./arrow.svg";
const Pagination = ({ items, itemsPerPage, currentPage, fetchApi }: any) => {
  const totalPages = Math.ceil(items / itemsPerPage);
  const [pageIndex, setPageIndex] = useState(currentPage || 1);
  const handlePageChange = (newPage: any) => {
    setPageIndex(newPage);
  };
  useEffect(() => {
    if (fetchApi) {
      fetchApi({
        "pagination[page]": pageIndex,
        "pagination[perPage]": itemsPerPage,
      });
    }
  }, [pageIndex]);
  return (
    <PaginiContainer
      className="panigation"
      style={{ display: items > itemsPerPage ? "" : "none" }}
    >
      <ButtonAction
        onClick={() => handlePageChange(pageIndex - 1)}
        className="item"
        disabled={pageIndex === 1 || items === 1}
      >
        <img style={{ transform: "rotateZ(180deg)" }} src={arrow} alt="" />
      </ButtonAction>
      <BoxPage className="item content">
        <TextPagini className="active">{totalPages ? pageIndex : 0}</TextPagini>
        <div>/</div>
        <div>{totalPages}</div>
      </BoxPage>
      <ButtonAction
        onClick={() => handlePageChange(pageIndex + 1)}
        className="item"
        disabled={pageIndex >= totalPages || items === 1}
      >
        <img  src={arrow} alt="" />
      </ButtonAction>
    </PaginiContainer>
  );
};

export default Pagination;
