import styled from "styled-components";

export const NavMobileContainer = styled.div`
  position: fixed;
  bottom: 15px;
  width: calc(100% - 40px);
  left: 50%;
  transform: translateX(-50%);
  border-radius: 16px;
  border: 1px solid #444750;
  background: #1B1C20;
  z-index: 1000;
  padding: 10px;
  display: none;
  align-items: center;
  justify-content: space-between;
  a {
    display: flex;
    flex-direction: column;
    color: #92929E;
    text-align: center;
    font-family: "AnekLatin-Regular";
    font-size: 15px;
    line-height: 14px;
    &.active {
      figure {
        img {
          filter: unset;
        }
      }
    }
    figure {
      width: 28px;
      height: 28px;
      margin: 0 auto 8px;
      img {
        filter: grayscale(0.9);
      }
    }
  }
  @media only screen and (max-width: 1024px){
    display: flex;
  }
`