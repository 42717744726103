import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ENDPOINT, { PRODUCT } from "../../services/Endpoint";
import type { FulfilledAction, PendingAction, RejectedAction } from "../utils/utils";
import { instance } from "../instance";

const initialState:any = {
  dashBoardInfo: null,
  loading: false,
  currentRequestId: null,
  ListLeaderBoard: [],
  airdropList: null,
};
export const getDasboardInfo = createAsyncThunk(`${PRODUCT}/_dashboard`, async (thunkAPI) => {
  try {
    const response = await instance.get(ENDPOINT.dashBoardInfo, {
    });
    return response;
  } catch (ex: any) {
    return ex;
  }
});


const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    //
  },
  extraReducers(builder) {
    builder
      .addCase(getDasboardInfo.fulfilled, (state, action) => {
        console.log(action.payload)
        if (action.payload?.status === 200) {
          console.log('iscalled')
          state.dashBoardInfo = action.payload.data;
          console.log('isSetStored')
        } else {
          state.dashBoardInfo = {};
          // window.location.reload();
        }
      })
      .addMatcher<PendingAction>(
        (action) => action.type.endsWith("/pending"),
        (state, action) => {
          state.loading = true;
          state.currentRequestId = action.meta.requestId;
        }
      )
      .addMatcher<RejectedAction | FulfilledAction>(
        (action) => action.type.endsWith("/rejected") || action.type.endsWith("/fulfilled"),
        (state, action) => {
          if (state.loading && state.currentRequestId === action.meta.requestId) {
            state.loading = false;
            state.currentRequestId = null;
          }
        }
      );
  },
});

const dashboardReducer = dashboardSlice.reducer;

export default dashboardReducer;
