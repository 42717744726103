import { useContext, useEffect, useRef, useState } from "react";
import { ContextProviderWrapper } from "../../../components/Context";
import { BoxTitleRadius } from "../../Dashboard/styled";
import {
  BoxBoxBlance,
  BoxInputTyprNumber,
  BoxNotification,
  InputStakeBox,
  StakeTON,
  StakeTONConten,
  Submit,
  TextUsdt,
} from "../styled";
import { sha256 } from "js-sha256";
import { useBalance } from "../../../hook/useTonBalance";
import { dispatch, RootState, useSelector } from "../../../store/reDucStore";
import { InvestStatus, SubmitInvest, getInvestInfo } from "../../../store/Slices/invesmens";
import { fromNano, toNano } from "@ton/core";
import toast from "react-hot-toast";
import {
  useTonAddress,
  useTonConnectUI,
  useTonWallet,
} from "@tonconnect/ui-react";
import NotiIcon from "../../../assets/Staking/Noti.svg";
import { formatNumber } from "../../../utils/formatNumber";
import axios from "axios";
import { Coins } from "ton3-core";

const StakeTon = () => {
  const { isMobile } = useContext(ContextProviderWrapper)!;
  // const { tonBalance } = useBalance();
  const adminAddress = useSelector(
    (state: RootState) => state.investmens.adminAddress
  );
  const [amount, setAmount] = useState(0);
  const [loadingSub, setLoadingSub] = useState(false);
  const addressHas = useTonAddress(false);
  const TokenInfo = useSelector((state: RootState) => state.token.TokenInfo);
  const [tonConnectUI] = useTonConnectUI();
  const wallet = useTonWallet();
  const [tonBalance, setTonBalance] = useState<any>(0);
  const timeOut = useRef<any>();
  useEffect(() => {
    getBalance();
  }, []);
  const address = useTonAddress();

  const getBalance = async () => {
    await axios
      .get(`https://tonapi.io/v2/accounts/${wallet?.account?.address}`)
      .then((response: any) => {
        setTonBalance(Coins.fromNano(response?.data?.balance || 0));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  const handleInvestment = async () => {
    if (amount > 0) {
      if (
        !adminAddress.address_invest ||
        !Number(amount) ||
        !TokenInfo?.prices?.USD
      ) {
        toast.error("There is an error. Please try again.");
        return;
      }
      if (amount < 100) {
        toast.error("At least $100");
        return;
      }
      let amountSubmit = `${toNano(
        (Number(amount) + 1) / TokenInfo?.prices?.USD
      )}`;
      console.log("amountSubmit", amountSubmit);

      setLoadingSub(true);
      const transaction = {
        validUntil: Math.floor(Date.now() / 1000) + 360,
        messages: [
          {
            address: "UQDSqbuhV3j1cq-n6qiU6viwz8DKQaUjNCDYCHJhCLT_NWwz",
            // amount: `${toNano(amount)}`,
            amount: amountSubmit,
          },
        ],
      };
      console.log("transaction", transaction);

      try {
        // const apiKey = sha256.hex(
        //   `hZcxtc2wQ2KfiKQnHxB322pE9DPxWGAMduADBAwtkr9MerMLW8${address}`
        // );

          const res = await tonConnectUI.sendTransaction(transaction);
          if (res.boc) {
            await dispatch(
              // @ts-ignore
              SubmitInvest({
                amount: Number(amount) / Number(TokenInfo?.prices?.USD),
              })
            );
            await dispatch(InvestStatus());
            // if (resSubmitInvest.payload.status === 200) {
            toast.success(
              "The investment package is being updated. Please wait a few minutes."
            );
            // }
          }
          // console.log("res---------------", res);
          // if (res.meta.requestStatus === "fulfilled") {
          //   dispatch(getInvestInfo());
          // } else {
          //   toast.error("There is an error. Please try again.");
          // }
      } catch (e) {
        console.log("e--------------------------", e);
      }
      timeOut.current = setTimeout(() => {
        setAmount(0);
        setLoadingSub(false);
      }, 3000);
    } else {
      toast.error("invalid amount");
    }
  };
  useEffect(() => {
    if (amount === 0 && timeOut.current) {
      clearTimeout(timeOut.current);
    }
  }, [amount]);
  return (
    <StakeTON>
      <BoxTitleRadius>{!isMobile && <h3>Stake TON</h3>}</BoxTitleRadius>
      <StakeTONConten>
        <InputStakeBox>
          <BoxBoxBlance>
            <p>Available: &nbsp;</p>
            <h6>
              {" "}
              {formatNumber(Number(tonBalance.toString())?.toFixed(2)) ||
                0}{" "}
              <span>
                <img src="./assets/ton_symbol.svg" alt="ton_symbol" />
              </span>
            </h6>
          </BoxBoxBlance>
          <BoxInputTyprNumber>
            <h6>Amount ($)</h6>
            <div>
              <input
                type="number"
                pattern="0.00"
                value={amount ? amount : ""}
                onChange={(e: any) => {
                  e.target.value >= 0 && setAmount(e.target.value);
                }}
              />
              <button
                onClick={() => {
                  Number(tonBalance.toString()) &&
                    TokenInfo?.prices?.USD &&
                    setAmount(
                      Number(tonBalance.toString()) * TokenInfo?.prices?.USD
                    );
                }}
              >
                Max
              </button>
            </div>
          </BoxInputTyprNumber>
          <TextUsdt>
            ~
            {TokenInfo?.prices?.USD
              ? formatNumber(
                  (Number(amount) / TokenInfo?.prices?.USD)?.toFixed(2)
                )
              : 0}{" "}
            <img src="./assets/ton_symbol.svg" style={{ maxHeight: "20px" }} />
          </TextUsdt>
        </InputStakeBox>
        <BoxNotification>
          <div>
            <img src={NotiIcon} alt="icon" />
          </div>
          <p>Start by staking at least $100 worth of $TON</p>
        </BoxNotification>
        <Submit
          onClick={() => {
            !loadingSub &&
              amount && amount >= 100 &&
              Number(amount) / TokenInfo?.prices?.USD <=
                Number(tonBalance.toString()) &&
              handleInvestment();
          }}
          style={{
            opacity:
              amount && amount >= 100 &&
              Number(amount) / TokenInfo?.prices?.USD <=
                Number(tonBalance.toString())
                ? "1"
                : "0.5",
          }}
        >
          {" "}
          {loadingSub ? "Loading..." : "Staking"}
        </Submit>
      </StakeTONConten>
    </StakeTON>
  );
};

export default StakeTon;
